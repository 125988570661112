<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="我的资料"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell-group class="mb-3">
        <van-cell title="昵称" :value="state.user.nickname" />
        <van-cell
          title="电话"
          :value="state.user.phone"
          is-link
          @click="unbind"
        />
      </van-cell-group>
      <van-cell-group>
        <van-cell icon="revoke" title="退出登录" @click="logout" is-link />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
import { Dialog, Field, Toast } from "vant";
import { LOGOUT, FORCE_LOGIN } from "@/store/modules/auth";
import { unbindPhone } from "@/api/account.service";
import { useRouter } from "vue-router";
export default {
  name: "StationDetail",
  components: {
    [Field.name]: Field,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      loading: true,
    });

    const unbind = () => {
      Dialog.confirm({
        title: "解绑手机",
        message:
          "解绑后您将立即登出, 请重新以新的手机号码登录即可完成自动绑定?",
      })
        .then(() => {
          processUnbindPhone();
        })
        .catch(() => {
          // on cancel
        });
    };

    const processUnbindPhone = async () => {
      try {
        await unbindPhone();
        store.dispatch(LOGOUT).then(() => {
          router.push({ name: "Login", query: { action: "unbind-phone" } });
        });
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const logout = async () => {
      Dialog.confirm({
        title: "退出登录",
        message: "请确认是否退出登录?",
      })
        .then(() => {
          store.dispatch(LOGOUT).then(() => {
            store.commit(FORCE_LOGIN);
            router.push({ name: "Login", query: { action: "user-logout" } });
          });
        })
        .catch(() => {
          // on cancel
        });
    };

    onMounted(() => {
      setTimeout(() => {
        state.user = store.getters.currentUser;
        state.loading = false;
      }, 300);
    });

    return { state, unbind, logout };
  },
};
</script>
